import React, { useEffect, useState } from 'react';
import logo from './assets/logo.png';
import success from './assets/success.png';
import oos from './assets/oos.png';
import overview from './assets/overview.png';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import useFuseStatus from "./hooks/useFuseStatus";

function Header() {
    return (
        <div className="flex pb-5 justify-between items-center">
            <img alt="Fuse Robotics" className="w-20" src={logo} />
            <div className="flex justify-end items-center gap-8 text-white md:text-lg">
                <a className="hidden md:block" href="#">Home</a>
                <a className="hidden md:block" href="//twitter.com/fuserobotics">Twitter</a>
                <a className="bg-fuse-orange pl-6 pr-6 pt-2 pb-2 rounded-full" href="//dash.fuserobotics.io">Dashboard</a>
            </div>
        </div>
    )
}

function Description() {
    return (
        <div className="flex gap-6 text-white flex-col w-full md:w-2/3">
            <p className="text-3xl">Automation redefined.</p>
            <p className="text-gray-600 text-xl max-w-lg">Fuse Chrome is a state-of-the-art Chrome extension tailored to assist you in purchasing limited release items at retail cost.</p>
            <a href="#" className="bg-fuse-orange pl-6 pr-6 pt-2 pb-2 rounded-full w-max text-lg">Out of stock</a>
        </div>
    )
}

function Image() {
    return (
        <img alt="Fuse Chrome" src={overview} className="w-full md:w-3/5"/>
    )
}

function Landing() {
    return (
        <div className="flex justify-between flex-col gap-10 items-center md:flex-row">
            <Description />
            <Image />
        </div>
    )
}

function UpdateSuccess() {
    return (
        <div className="text-center text-xl text-white flex justify-center items-center gap-5 flex-col">
            <img src={success} alt="Success" className="w-32" />
            Fuse Chrome has been successfully updated.
            <br />
            The extension will now automatically reload to apply all changes.
        </div>
    )
}

function UpdateFailed() {
    return (
        <div className="text-center text-xl text-white flex justify-center items-center gap-5 flex-col">
            <img src={oos} alt="Success" className="w-32" />
            Fuse Chrome is not installed, please open this URL in the browser where the extension is installed or reload the extension manually.
        </div>
    )
}

function UpdateLoading() {
    return (
        <div className="text-center text-xl text-white">
            Fetching installation status...
        </div>
    )
}

function Update() {
    const installed = useFuseStatus()
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setTimeout(() => setLoaded(true), 1000)
    })

    return (
        <div className="flex justify-center items-center">
            {
                !loaded ? UpdateLoading() : installed ? UpdateSuccess() : UpdateFailed()
            }
        </div>
    )
}

function Footer() {
    return (
        <div className="flex justify-center items-center w-full text-gray-700 text-center flex-col">
            <div className="flex justify-center items-center w-full gap-4 text-gray-500 text-center">
                <a href="legal/privacy.html">Privacy Policy</a>
                <a href="legal/terms.html">Terms of Service</a>
                <a href="legal/refund.html">Refund Policy</a>
            </div>
            <p>© Fuse Robotics LTD Registered business in England and Wales 13211130</p>
        </div>
    )
}

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <div className="bg-bg-light pl-5 pr-5 flex flex-col justify-between min-h-screen w-auto pt-10 pb-5 gap-10 md:pl-20 md:pr-20">
                        <Header />
                        <Landing />
                        <Footer />
                    </div>
                </Route>
                <Route exact path="/update">
                    <div className="bg-bg-light pl-5 pr-5 flex flex-col justify-between min-h-screen w-auto pt-10 pb-5 gap-10 md:pl-20 md:pr-20">
                        <Header />
                        <Update />
                        <Footer />
                    </div>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
