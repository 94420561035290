import { useEffect, useState } from "react";

export default () => {
    const [installed, setInstalled] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            const fuseInstalled = document.querySelector("fuse-installed")
            if (fuseInstalled) {
                setInstalled(true)
                console.log(installed)
                clearInterval(interval)
            }
        }, 75)

        return () => clearInterval(interval)
    }, [])

    return installed
}